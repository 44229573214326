import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <div className="fixed right-0 w-52 -z-1 sm:w-80 md:w-auto md:max-w-md lg:max-w-xl">
      <StaticImage
        alt=""
        src="../images/bg-1.png"
      />
    </div>

    <div className="bottom-0 fixed -z-1 xl:-left-48">
      <StaticImage
        alt=""
        src="../images/bg-2.png"
      />
    </div>

    <div className="flex flex-col h-screen pt-12">
      <div className="container flex-1 mx-auto px-4">
        <div className="flex flex-col items-center justify-center text-center sm:flex-row sm:text-left">
          <div className="mb-12 sm:mb-0 sm:mr-12 lg:mr-24 xl:mr-36">
            <StaticImage
              alt="exsurgo strong and fit logo"
              src="../images/gym-logo.png"
              width={240}
            />

            <h2 className="font-oswald mb-1 mt-4 text-3xl uppercase">
              Exsurgo Strong &amp; Fit
            </h2>

            <h3 className="mb-8">
              RISE. REGAIN. RECOVER.
            </h3>

            <a
              className="bg-orange font-oswald inline-block px-6 py-3 rounded-full transition-opacity hover:opacity-70"
              href="https://strongandfit.exsurgo.us"
            >
              VISIT SITE
            </a>
          </div>

          <div className="sm:max-w-md lg:max-w-xl xl:max-w-2xl">
            <StaticImage
              alt="exsurgo strong and fit"
              src="../images/gym.png"
            />
          </div>
        </div>

        <div className="flex flex-col-reverse items-center justify-center mt-36 text-center sm:flex-row sm:text-left">
          <div className="sm:max-w-md lg:max-w-xl xl:max-w-2xl">
            <StaticImage
              alt="exsurgo technologies"
              src="../images/tech.png"
            />
          </div>

          <div className="mb-12 sm:mb-0 sm:ml-12 lg:ml-24 xl:ml-36">
            <StaticImage
              alt="exsurgo technologies logo"
              src="../images/tech-logo.png"
              width={240}
            />

            <h2 className="font-oswald mb-1 mt-4 text-3xl uppercase">
              Exsurgo Technologies
            </h2>

            <h3 className="mb-8">
              REAL-TIME FEEDBACK ON SPEED, STRENGTH, AND POWER.
            </h3>

            <a
              className="bg-grey font-oswald inline-block px-6 py-3 rounded-full text-white transition-opacity hover:opacity-70"
              href="https://exsurgo.tech"
            >
              VISIT SITE
            </a>
          </div>
        </div>
      </div>

      <div className="bg-grey mt-8 py-8">
        <div className="text-center text-white">
          <StaticImage
            alt=""
            className="mb-4"
            src="../images/logo-white.png"
            width={200}
          />

          <p>21730 Red Rum Drive, #107, Ashburn, VA, 20147</p>

          <p className="mt-4">
            &copy; Copyright EXSURGO {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
